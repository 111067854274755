export default {
    methods:{
      scrollToCatalog() {
        // let options = {
        //   duration: 0,
        //   offset: this.$device.isMobile ? -96 : -124,
        //   force: true
        // }
        // setTimeout(() => {
        //   this.$scrollTo('#catalog', options)
        // }, 25)
  
      }
    }
  }