
  import { mapActions, mapGetters, mapMutations } from "vuex";
  import declension from "@/mixins/declension";
  import offerFilters from "@/apollo/queries/offer/offerFilters";
//   import filtersCount from "@/apollo/queries/filtersCount";
  import scrollToElement from "@/mixins/scrollToElement";
  
  export default {
	mixins: [declension, scrollToElement],
	mounted() {
	  this.getPathParams();
	  this.getFilters();
	},
	watch: {
	//   count() {
	// 	this.countLoader = true;
	// 	setTimeout(() => {
	// 	  this.countLoader = false;
	// 	}, 480);
	//   }
	},
	data() {
	  return {
		isChosen: false,
		filter: {
		  folder: null,
		  generation: null,
		  engineType: null,
		  bodyType: null,
		  driveType: null,
		  gearbox: null,
		  year: null,
		  price: null,
		  owner: null
		},
		chosen: {
		  mark: null,
		  folder: null,
		  generation: null,
		  engineType: null,
		  bodyType: null,
		  driveType: null,
		  gearbox: null,
		  year: null,
		  price: null,
		  owner: null
		},
		count: 0,
		countLoader: false
	  };
	},
	computed: {
	  ...mapGetters({
		markFolderGeneration: 'marks/marks/markFolderGeneration',
	  })
	},
	methods: {
	  ...mapActions({
		request: "request"
	  }),
	  ...mapMutations({
		setIsGetOffers: "catalog/catalog-cars/SET_IS_GET_OFFERS",
		setIsFilterClick: "catalog/catalog-cars/SET_IS_FILTER_CLICK"
	  }),
	  rangeValue(min, max, step) {
		let range = [];
		while (min < max + step) {
		  range.push(min);
		  min += step;
		}
		return range;
	  },
	  getQueryParams() {
		if (this.$route.query.engineType) {
		  this.chosen.engineType = this.filter.engineType?.find(item => item.id === Number(this.$route.query.engineType));
		}
		if (this.$route.query.gearbox) {
		  this.chosen.gearbox = this.filter.gearbox?.find(item => item.id === Number(this.$route.query.gearbox));
		}
		if (this.$route.query.bodyType) {
		  this.chosen.bodyType = this.filter.bodyType?.find(item => item.id === Number(this.$route.query.bodyType));
		}
		if (this.$route.query.driveType) {
		  this.chosen.driveType = this.filter.driveType?.find(item => item.id === Number(this.$route.query.driveType));
		}
		if (this.$route.query.year_from) {
		  this.chosen.year = this.filter.year?.find(item => item.value === Number(this.$route.query.year_from));
		}
		if (this.$route.query.price_to) {
		  this.chosen.price = this.filter.price?.find(item => item.value === Number(this.$route.query.price_to));
		}
		if (this.$route.query.owner) {
		  this.chosen.owner = this.filter.owner?.find(item => item.id === Number(this.$route.query.owner));
		}
	  },
	  getPathParams() {
		let generationsFull = null
		if (this.$route.params.mark) {
		  this.chosen.mark = this.markFolderGeneration.find(item => item.slug === this.$route.params.mark);
		  this.filter.folder = this.chosen.mark?.folders;
		}
		if (this.$route.params.model) {
			this.chosen.folder = this.chosen.mark?.folders.find(item => item.slug === this.$route.params.model);
       		this.filter.generation = this.chosen.folder?.generations;
		//   this.chosen.folder = this.chosen.mark?.folders.find(item => item.slug === this.$route.params.model);

		  generationsFull = [...this.filter.generation]
		  generationsFull = generationsFull.map(item => {
				        let generation = item.name + ` [${item.year_begin} - ${item.year_end || ' н.в.'}]`
				        return { ...item, name: generation }
			 });

			this.filter.generation = generationsFull
		}
		if (this.$route.params.car && generationsFull) {
		  this.chosen.generation = generationsFull.find(item => item.slug === this.$route.params.car);
		}
	  },
	//   async getCount() {
	// 	let variables = {
	// 	  mark_slug_array: this.chosen.mark ? [this.chosen.mark.slug] : null,
	// 	  folder_slug_array: this.chosen.folder ? [this.chosen.folder.slug] : null,
	// 	  generation_slug_array: this.chosen.generation ? [this.chosen.generation.slug] : null,
	// 	  engine_type_id_array: this.chosen.engineType ? [Number(this.chosen.engineType.id)] : null,
	// 	  body_type_id_array: this.chosen.bodyType ? [Number(this.chosen.bodyType.id)] : null,
	// 	  gearbox_id_array: this.chosen.gearbox ? [Number(this.chosen.gearbox.id)] : null,
	// 	  drive_type_id_array: this.chosen.driveType ? [Number(this.chosen.driveType.id)] : null,
	// 	  owner_id_array: this.chosen.owner ? [Number(this.chosen.owner.id)] : null,
	// 	  year_from: this.chosen.year ? Number(this.chosen.year.value) : null,
	// 	  price_to: this.chosen.price ? Number(this.chosen.price.value) : null
	// 	};
	// 	let response = await this.request({
	// 	  query: filtersCount,
	// 	  variables
	// 	});
	// 	this.count = response.data.offers.total;
	//   },
	  async getFilters() {
		let variables = {
		  mark_slug_array: this.chosen.mark ? [this.chosen.mark.slug] : null,
		  folder_slug_array: this.chosen.folder ? [this.chosen.folder.slug] : null,
		  generation_slug_array: this.chosen.generation ? [this.chosen.generation.slug] : null,
		  engine_type_id_array: this.$route.query.engineType ? [Number(this.$route.query.engineType)] : null,
		  body_type_id_array: this.$route.query.bodyType ? [Number(this.$route.query.bodyType)] : null,
		  gearbox_id_array: this.$route.query.gearbox ? [Number(this.$route.query.gearbox)] : null,
		  drive_type_id_array: this.$route.query.driveType ? [Number(this.$route.query.driveType)] : null,
		  owner_id_array: this.$route.query.owner ? [Number(this.$route.query.owner)] : null,
		  year_from: this.$route.query.year_from ? Number(this.$route.query.year_from) : null,
		  price_to: this.$route.query.price_to ? Number(this.$route.query.price_to) : null
		};
		try {
		  let response = await this.request({
			query: offerFilters,
			variables
		  });
		  for (let i in response.data.offerFilters) {
			//проверка на цену или год
			if (response.data.offerFilters[i][0]) {
			  if (typeof response.data.offerFilters[i][0] === "object") {
				if (response.data.offerFilters[i]) {
				  this.filter[i] = response.data.offerFilters[i];
				  //если в селекте одно значение сразу его подставляю
				  if (this.filter[i].length === 1) {
					this.chosen[i] = this.filter[i][0];
				  }
				}
			  } else {
				switch (i) {
				  case "year":
					if (response.data.offerFilters.year[0]) {
					  let year = [];
					  year = this.rangeValue(response.data.offerFilters.year[0], response.data.offerFilters.year[1], 1);
					  year = year.map(value => {
						return {
						  title: "от " + value + " г.",
						  value: value
						};
					  });
					  this.filter.year = year;
					}
					break;
				  case "price":
					if (response.data.offerFilters.price[0]) {
					  let price = [];
					  price = this.rangeValue(response.data.offerFilters.price[0]+100000, response.data.offerFilters.price[1], 100000);
					  price = price.map(value => {
						return {
						  title: "до " + new Intl.NumberFormat("ru-RU").format(value) + " ₽",
						  value: value
						};
					  });
					  this.filter.price = price;
					}
					break;
				}
			  }
			}
		  }
		  this.getQueryParams();
		} catch (e) {
		  console.log(e);
		}
	  },
	  async clearChosenQueries() {
		if (JSON.stringify(this.$route.query) !== "{}") {
		  await this.$router.replace({ query: null });
		}
		this.chosen.engineType = null;
		this.chosen.bodyType = null;
		this.chosen.driveType = null;
		this.chosen.gearbox = null;
		this.chosen.year = null;
		this.chosen.price = null;
		this.chosen.owner = null;
	  },
	  async markSelect(option) {
		this.isChosen = true; // нужно для кнопки показать
		this.chosen.mark = option;
		this.count = option?.offers_count;
		this.filter.folder = option.folders;
		this.filter.generation = null;
		this.chosen.folder = null;
		this.chosen.generation = null;
		// - зпрещаю отправлять запрос на тачки пока меняется роут
		await this.setIsGetOffers(false);
		await this.clearChosenQueries();
		// - разрешаю отправлять запрос на тачки
		await this.setIsGetOffers(true);
		await this.getFilters();
	  },
	  async folderSelect(option) {
		this.isChosen = true; // нужно для кнопки показать
		this.chosen.folder = option;
		this.count = option?.offers_count;
		this.chosen.generation = null;
		this.filter.generation = option.generations.map(item => {
		  let generation = item.name + ` [${item.year_begin} - ${item.year_end || " н.в."}]`;
		  return { ...item, name: generation };
		});
		this.filter.generation  = [...this.filter.generation.sort((a, b) => a.year_begin - b.year_begin)]

		// - запрещаю отправлять запрос на тачки пока меняется роут
		await this.setIsGetOffers(false);
		await this.clearChosenQueries();
		await this.setIsGetOffers(true);
		// - разрешаю отправлять запрос на тачки
		await this.getFilters();
	  },
	  async generationSelect(option) {
		this.isChosen = true; // нужно для кнопки показать
		this.count = option?.offers_count;
		this.chosen.generation = option;
		// - зпрещаю отправлять запрос на тачки пока меняется роут
		await this.setIsGetOffers(false);
		await this.clearChosenQueries();
		// - разрешаю отправлять запрос на тачки
		await this.setIsGetOffers(true);
		await this.getFilters();
  
	  },
	  async querySelect(item, option) {
		this.isChosen = true; // нужно для кнопки показать
		this.chosen[option] = item;
		// await this.getCount();
	  },
	  async onFilter() {
		let route = `/cars`;
  
		if (this.chosen.mark) {
		  route += `/${this.chosen.mark.slug}`;
		}
		if (this.chosen.folder) {
		  route += `/${this.chosen.folder.slug}`;
		}
		if (this.chosen.generation) {
		  route += `/${this.chosen.generation.slug}`;
		}
  
		let query = {};
  
  
		if (this.chosen.engineType) {
		  query.engineType = this.chosen.engineType.id;
		}
		if (this.chosen.gearbox) {
		  query.gearbox = this.chosen.gearbox.id;
		}
		if (this.chosen.bodyType) {
		  query.bodyType = this.chosen.bodyType.id;
		}
		if (this.chosen.driveType) {
		  query.driveType = this.chosen.driveType.id;
		}
		if (this.chosen.year) {
		  query.year_from = this.chosen.year.value;
		}
		if (this.chosen.year) {
		  query.year_from = this.chosen.year.value;
		}
		if (this.chosen.price) {
		  query.price_to = this.chosen.price.value;
		}
		if (this.chosen.owner) {
		  query.owner = this.chosen.owner.id;
		}

		query.toCar = 'toCar'
  
		if (this.$route.path === route) {
		  await this.$router.replace({ query: query }).catch(() => {
			return false;
		  });
		  await this.getFilters();
		  await this.scrollToCatalog();
		} else {
		  await this.$router.push({ path: route, query });
		  await this.setIsFilterClick(true);
		}
  
	  },
	  async resetFilters() {
		this.isChosen = false; // нужно для кнопки показать
		this.chosen.mark = null;
		this.filter.folder = null;
		this.filter.generation = null;
		this.chosen.folder = null;
		this.chosen.generation = null;
		// - зпрещаю отправлять запрос на тачки пока меняется роут
		await this.setIsGetOffers(false);
		await this.clearChosenQueries();
		// - разрешаю отправлять запрос на тачки
		await this.setIsGetOffers(true);
		await this.getFilters();
	  }
	}
  };
  