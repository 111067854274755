
  export default {
    data() {
      return {
        open: false
      };
    },
    props: {
      title: String,
      items: Array,
      value: [Object, Number, String]
    },
    computed: {
      currentTitle() {
        if (this.value) {
          return this.value.title || this.value.name || this.value.value || this.value;
        } else {
          return this.title;
        }
      },
      currentValue: {
        get() {
          return this.value?.name || this.value?.title || this.value || this.title;
        },
        set() {
          return this.value?.name || this.value?.title || this.value;
        }
      }
    },
    methods: {
      changeSelect() {
        let option = this.items.find(item => item.title === event.target.value);
        if (option) {
          this.$emit("selected", option);
        } else {
          option = this.items.find(item => item.name === event.target.value);
          this.$emit("selected", option);
        }
      },
      onSelect(item) {
        this.open = false;
        this.$emit("selected", item);
      }
    }
  };
  